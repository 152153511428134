import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

export class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isFixed: false};
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll() {
    const scrollpos = window.scrollY;
    this.setState({
      isFixed: (scrollpos > 10)
    });
  }

  render() {
    return (
      <nav id="header" className={this.state.isFixed ? 'fixed w-full z-30 top-0 text-white bg-white' : 'fixed w-full z-30 top-0 text-white '}>
    
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
            
          <div className="pl-4 flex items-center">
            <Link to="/" className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
              <svg width="40px" height="40px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                  <g id="segeln-in-der-karibik" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <circle id="Oval" fill="#244661" cx="256" cy="256" r="256"></circle>
                      <g id="sailing-boat" transform="translate(89.000000, 78.000000)" fill="#FFFFFF" fillRule="nonzero">
                          <path d="M18.6085714,253.473684 C25.1295238,271.929737 42.752,285.157895 63.6190476,285.157895 L270.380952,285.157895 C291.152571,285.157895 308.822762,271.913895 315.375524,253.473684 L18.6085714,253.473684 Z M174.952381,237.631579 L334,237.631579 C334,272.563421 305.514571,301 270.396857,301 L63.6190476,301 C28.4854286,301 0.0159047619,272.626789 0.0159047619,237.631579 L159.063524,237.631579 L159.063524,0 L174.968286,0 L174.968286,237.631579 L174.952381,237.631579 Z M334,221.789474 L190.857143,79.2105263 L190.857143,221.789474 L334,221.789474 Z M294.238095,205.947368 L206.761905,205.947368 L206.761905,118.815789 L294.238095,205.947368 Z M47.7142857,87.1315789 L47.7142857,87.1315789 C95.4285714,7.92105263 143.142857,0 143.142857,0 C143.142857,0 127.238095,55.4473684 127.238095,126.736842 C127.238095,198.026316 143.142857,221.789474 143.142857,221.789474 L0,221.789474 C0,221.789474 0,166.342105 47.7142857,87.1315789 Z M54.0284762,107.868895 C17.4952381,176.940474 17.4952381,205.947368 17.4952381,205.947368 L120.87619,205.947368 C120.87619,205.947368 111.333333,177.289 111.333333,126.736842 C111.333333,76.1846842 120.87619,28.0722105 120.87619,28.0722105 C120.87619,28.0722105 85.2495238,48.8570526 54.0284762,107.868895 L54.0284762,107.868895 L54.0284762,107.868895 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg>
              {/* Segeln in der Karibik */}
            </Link>
          </div>
    
          <div className="block lg:hidden pr-4">
            <button id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none">
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
          </div>
    
          <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
            <ul className="list-reset lg:flex justify-end flex-1 items-center">
              <li className="mr-3">
                <Link to="/buecher/" className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4">Bücher</Link>
              </li>
              {/*
              <li className="mr-3">
                <a className="inline-block py-2 px-4 text-black font-bold no-underline" href="#">Active</a>
              </li>
              <li className="mr-3">
                <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#">link</a>
              </li>
              <li className="mr-3">
                <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4" href="#">link</a>
              </li>
              */}
            </ul>
            {/*
            <button id="navAction" className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75">Action</button>
            */}
          </div>
        </div>
        
        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>

    );
  }
}

export default Navigation
