import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="bg-white">
    <div className="container mx-auto  px-8">
      <div className="w-full flex flex-col md:flex-row py-6">          
        <div className="flex-1 mb-6">

          <Link to="/" className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
            <svg width="80px" height="80px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                <g id="segeln-in-der-karibik" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle id="Oval" fill="#244661" cx="256" cy="256" r="256"></circle>
                    <g id="sailing-boat" transform="translate(89.000000, 78.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <path d="M18.6085714,253.473684 C25.1295238,271.929737 42.752,285.157895 63.6190476,285.157895 L270.380952,285.157895 C291.152571,285.157895 308.822762,271.913895 315.375524,253.473684 L18.6085714,253.473684 Z M174.952381,237.631579 L334,237.631579 C334,272.563421 305.514571,301 270.396857,301 L63.6190476,301 C28.4854286,301 0.0159047619,272.626789 0.0159047619,237.631579 L159.063524,237.631579 L159.063524,0 L174.968286,0 L174.968286,237.631579 L174.952381,237.631579 Z M334,221.789474 L190.857143,79.2105263 L190.857143,221.789474 L334,221.789474 Z M294.238095,205.947368 L206.761905,205.947368 L206.761905,118.815789 L294.238095,205.947368 Z M47.7142857,87.1315789 L47.7142857,87.1315789 C95.4285714,7.92105263 143.142857,0 143.142857,0 C143.142857,0 127.238095,55.4473684 127.238095,126.736842 C127.238095,198.026316 143.142857,221.789474 143.142857,221.789474 L0,221.789474 C0,221.789474 0,166.342105 47.7142857,87.1315789 Z M54.0284762,107.868895 C17.4952381,176.940474 17.4952381,205.947368 17.4952381,205.947368 L120.87619,205.947368 C120.87619,205.947368 111.333333,177.289 111.333333,126.736842 C111.333333,76.1846842 120.87619,28.0722105 120.87619,28.0722105 C120.87619,28.0722105 85.2495238,48.8570526 54.0284762,107.868895 L54.0284762,107.868895 L54.0284762,107.868895 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            {/* Segeln in der Karibik */}
          </Link>
        </div>
      
        {/* 
        <div className="flex-1">
          <p className="uppercase text-gray-500 md:mb-6">Links</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">FAQ</a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Help</a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Support</a>
            </li>
          </ul>
        </div>

        <div className="flex-1">
          <p className="uppercase text-gray-500 md:mb-6">Legal</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Terms</a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Privacy</a>
            </li>
          </ul>
        </div>
        <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Social</p>
            <ul className="list-reset mb-6">
                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Facebook</a>
                </li>
                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Linkedin</a>
                </li>
                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Twitter</a>
                </li>
            </ul>
        </div>
        <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">Company</p>
                  <ul className="list-reset mb-6">
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                          <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Official Blog</a>
                      </li>
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                          <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">About Us</a>
                      </li>
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                          <a href="#" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Contact</a>
                      </li>
                  </ul>
              </div>
              */}
      </div>
      © {new Date().getFullYear()}, Kopfwelt GmbH
    </div>
  
  </footer>
)

export default Footer
